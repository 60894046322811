import { Injectable, Injector } from '@angular/core';
import { ApplicationService } from '@app/shared/application/application.service';
import { CodeService } from '@app/shared/common/code/code.service';
import { ProfessionConfigurationService } from '@app/shared/services/profession-configuration.service';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { delay, map, switchMap, tap } from 'rxjs/operators';
import { AlaApplicantPersonDto, AlaApplicantPersonServiceProxy, AlaContactDto, AlaContactServiceProxy, AlaDiplomaDto, AlaDiplomaServiceProxy, AlaCurrentAssistantDto, AlaCurrentAssistantServiceProxy, AlaDocumentServiceProxy, AlaDocumentsOutput, AlaDto, AlaInvoiceAddressDto, AlaInvoiceAddressServiceProxy, AlaPersonDto, AlaPersonServiceProxy, AlapServiceProxy, AlasServiceProxy, AlaStatus, ApplicationDto, ApplicationServiceProxy, ApplicationType, ApplicationUploadFilesInput, CreateAlaInput, CreateAlaOutput, CreateOrUpdateAlaApplicantPerson, CreateOrUpdateAlaContact, CreateOrUpdateAlaDiploma, CreateOrUpdateAlaPerson, ExternalSystemEnum, GeneralDocumentGroupDto, GeneralFileDto, GeneralFileInfoDto, GetAlaDocumentsInput, GetAlaFileInput, GetAlaOutput, LiaTypeServiceProxy, ProfessionRegisterAffiliationServiceProxy, ProfessionRequirementType, CreateOrUpdateAlaCurrentAssistantsInput, AlaTrustworthinessServiceProxy, AlaAcademicTitleDto, CreateOrUpdateAlaAcademicTitleInput, CreateOrUpdateAlaAcademicTitleOutput, AlaAcademicTitleServiceProxy, AlaPreviousEmploymentServiceProxy, AlaPreviousEmploymentDto, CreateOrUpdateAlaPreviousEmploymentInput, AlaLanguageSkillDto, CreateOrUpdateAlaLanguageSkillInput, AlaLanguageSkillServiceProxy, AlaBusinessDto, AlaBusinessServiceProxy, AlaPostgraduateTitleServiceProxy, AlaPostgraduateTitleDto, CreateOrUpdateAlaPostgraduateTitleInput, CreateOrUpdateAlaPostgraduateTitleOutput, AlaApplicantSponsorshipDto, CreateOrUpdateAlaApplicantSponsorship, AlaApplicantSponsorshipServiceProxy, AlaEmploymentServiceProxy, AlaEmploymentDto, AlaMedicalFieldDirectorDto, AlaMedicalFieldDirectorServiceProxy, CreateOrUpdateAlaMedicalFieldDirector, ProfessionalLicenceDto, CorporateLicenceDto } from './../../../shared/service-proxies/service-proxies';

@Injectable()
export class AlaService extends ApplicationService {

    ala: AlaDto;
    alaResultDocuments: GeneralFileInfoDto[];
    healthRegisterSystem: ExternalSystemEnum;

    private alapsForPersonSubject = new ReplaySubject<AlaDto[]>(1);
    readonly alapsForPerson$ = this.alapsForPersonSubject.asObservable();

    constructor(
        injector: Injector,
        private appService: ApplicationServiceProxy,
        codeService: CodeService,
        private professionConfigurationService: ProfessionConfigurationService,
        private professionRegisterService: ProfessionRegisterAffiliationServiceProxy,
        private liaService: LiaTypeServiceProxy,
        private alapService: AlapServiceProxy,
        private alasService: AlasServiceProxy,
        private alaApplicantPersonServiceProxy: AlaApplicantPersonServiceProxy,
        private alaApplicantSponsorshipServiceProxy: AlaApplicantSponsorshipServiceProxy,
        private alaPersonServiceProxy: AlaPersonServiceProxy,
        private alaContactServiceProxy: AlaContactServiceProxy,
        private alaDiplomaServiceProxy: AlaDiplomaServiceProxy,
        private alaBusinessServiceProxy: AlaBusinessServiceProxy,
        private alaEmploymentServiceProxy: AlaEmploymentServiceProxy,
        private alaCurrentAssistantServiceProxy: AlaCurrentAssistantServiceProxy,
        private alaTrustworthinessServiceProxy: AlaTrustworthinessServiceProxy,
        private alaPreviousEmploymentServiceProxy: AlaPreviousEmploymentServiceProxy,
        private invoiceAddressService: AlaInvoiceAddressServiceProxy,
        private alaAcademicTitleServiceProxy: AlaAcademicTitleServiceProxy,
        private alaLanguageSkillServiceProxy: AlaLanguageSkillServiceProxy,
        private alaPostgraduateTitleServiceProxy: AlaPostgraduateTitleServiceProxy,
        private alaMedicalFieldDirectorServiceProxy: AlaMedicalFieldDirectorServiceProxy,
        private documentService: AlaDocumentServiceProxy,
    ) {
        super(injector, codeService, appService);
    }

    loadAlapsForPerson(gln: string): Observable<AlaDto[]> {
        if (gln) {
            return this.alapService.getAlapsForPerson(gln).pipe(
                tap(alaps => this.alapsForPersonSubject.next(alaps))
            );
        } else {
            this.alapsForPersonSubject.next([]);
            return of([]);
        }
    }

    getExternalSystemByProfessionId(professionId: string): Observable<ExternalSystemEnum> {
        return this.liaService.getExternalRegisterByProfessionId(professionId);
    }

    getActiveProfessions(): Observable<string[]> {
        return this.liaService.getActiveProfessions();
    }

    createAlap(input: CreateAlaInput): Observable<CreateAlaOutput> {
        return this.alapService.createAlap(input).pipe(tap((output: CreateAlaOutput) => {
            this.ala = null;
        }));
    }

    deleteAla(): Observable<void> {
        return this.appService.deleteApplication(this.ala.application.caseId);
    }

    getAla(caseId: string): Observable<GetAlaOutput> {
        this.ala = null;
        return this.getApplication(caseId).pipe(switchMap((app: ApplicationDto) => {
            if (app.type === ApplicationType.Alap) {
                return this.alapService.getAlap(caseId).pipe(tap((output: GetAlaOutput) => {
                    this.ala = output.ala;

                    return forkJoin([ this.professionConfigurationService.getRequirementByProfessionAndType(this.ala.professionId, ProfessionRequirementType.OnlyPrivateAddressAllowed), this.professionRegisterService.getAll(), of(output.ala) ]).subscribe(([result, affiliations, slap]) => {
                        this.healthRegisterSystem = affiliations.find(a => a.id === slap.professionId).externalSystem;
                    });
                }));
            } else {
                return this.alasService.getAla(caseId).pipe(tap((output: GetAlaOutput) => {
                    this.ala = output.ala;
                }));
            }
        }));
    }

    getMyAlaps(): Observable<AlaDto[]> {
        return this.alapService.getMyAlaps();
    }

    getMyAlases(): Observable<AlaDto[]> {
        return this.alasService.getMyAlases();
    }

    isInAlaStatus(expectedStatus: AlaStatus): boolean {
        return this.ala.statusId === expectedStatus;
    }

    getPersonalLicenceForAla(): Observable<ProfessionalLicenceDto> {
        return this.alapService.getLicenceForAla(this._caseId);
    }

    getCorporateLicenceForAla(): Observable<CorporateLicenceDto> {
        return this.alasService.getLicenceForAla(this._caseId);
    }

    getEvaluationComments(): Observable<string> {
        return this.alapService.getEvaluationComments(this._caseId);
    }

    releaseAla(comment: string): Observable<void> {
        switch (this.application.type) {
            case ApplicationType.Alap:
                return this.alapService.releaseAlap(this.ala.application.caseId, comment);
            case ApplicationType.Alas:
                return this.alasService.releaseAlas(this.ala.application.caseId, comment);
        }
    }

    getNextStatus(status: AlaStatus): Observable<AlaStatus> {
        let isAuthority = this.permission.isGranted('Pages.Authority.Applications');
        return this.alapService.getNextStatus(this._caseId, status).pipe(map(status => {
            // Authority goes to 'finish review' and not to 'release'
            if (isAuthority && status === AlaStatus.Release) {
                return AlaStatus.FinishReview;
            }
            return status;
        }));
    }

    getStepUrl(nextStep: AlaStatus): string {
        return this.getNextStep(this.getUrlForStep(nextStep));
    }

    getFile(step: AlaStatus, id: number): Observable<GeneralFileDto> {
        const input = new GetAlaFileInput();
        input.caseId = this._caseId;
        input.step = step;
        input.id = id;
        return this.documentService.getFile(input);
    }

    uploadFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadFiles(input);
    }

    deleteFile(step: AlaStatus, id: number): Observable<void> {
        return this.documentService.deleteFile(this._caseId, step, id);
    }

    get caseId(): string {
        return this.ala.application.caseId;
    }

    get professionId(): string {
        return this.ala?.professionId;
    }

    get sponsorshipTypeId(): string {
        return this.ala?.sponsorshipTypeId;
    }

    get healthRegisterSystemId(): ExternalSystemEnum {
        return this.healthRegisterSystem;
    }

    get gln(): string {
        return this.ala?.person?.gln;
    }

    getApplicantName(): Observable<string> {
        switch (this.application.type) {
            case ApplicationType.Alap:
                return of(this.ala.applicantPerson?.firstName + ' ' + this.ala.applicantPerson?.lastName);
            case ApplicationType.Alas:
                return of(this.ala.applicantSponsorship?.name);
        }
    }

    getApplicantPerson(): Observable<AlaApplicantPersonDto> {
        return of(this.ala.applicantPerson).pipe(delay(0));
    }

    createOrUpdateApplicantPerson(input: CreateOrUpdateAlaApplicantPerson): Observable<void> {
        this.setStepToReview(AlaStatus.ApplicantPerson);
        return this.alaApplicantPersonServiceProxy.createOrUpdate(input).pipe(tap((status: AlaStatus) => {
            this.ala.applicantPerson = input.applicantPerson;
            this.ala.currentStep = status;
        }))
        .pipe(map((status: AlaStatus) => null));
    }

    getApplicantSponsorship(): Observable<AlaApplicantSponsorshipDto> {
        return of(this.ala.applicantSponsorship).pipe(delay(0));
    }

    getApplicantSponsorshipFiles(forceRefresh?: boolean, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.documentService.getDocuments(new GetAlaDocumentsInput({ caseId: caseId, step: AlaStatus.ApplicantSponsorship })).pipe(tap((output: AlaDocumentsOutput) => {
                this.ala.applicantSponsorship.documents = output.documents;
            })).pipe(map((output: AlaDocumentsOutput) => output.documents));
        }
        return of(this.ala.applicantSponsorship.documents);
    }

    createOrUpdateApplicantSponsorship(input: CreateOrUpdateAlaApplicantSponsorship): Observable<void> {
        this.setStepToReview(AlaStatus.ApplicantSponsorship);
        return this.alaApplicantSponsorshipServiceProxy.createOrUpdate(input).pipe(tap((status: AlaStatus) => {
            this.ala.applicantSponsorship = input.applicantSponsorship;
            this.ala.currentStep = status;
        }))
        .pipe(map((status: AlaStatus) => null));
    }

    getPersonFiles(forceRefresh?: boolean, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.documentService.getDocuments(new GetAlaDocumentsInput({ caseId: caseId, step: AlaStatus.Person })).pipe(
                tap((output: AlaDocumentsOutput) => {
                    this.ala.documents = output.documents;
                }),
                map((output: AlaDocumentsOutput) => output.documents)
            );
        }
        return of(this.ala.person?.documents || []);
    }

    getPerson(): Observable<AlaPersonDto> {
        return of(this.ala.person).pipe(delay(0));
    }

    createOrUpdatePerson(input: CreateOrUpdateAlaPerson): Observable<void> {
        this.setStepToReview(AlaStatus.Person);
        return this.alaPersonServiceProxy.createOrUpdate(input).pipe(tap((status: AlaStatus) => {
            this.ala.person = input.person;
            this.ala.currentStep = status;
        }))
        .pipe(map((status: AlaStatus) => null));
    }

    getMedicalFieldDirector(): Observable<AlaMedicalFieldDirectorDto> {
        return of(this.ala.medicalFieldDirector).pipe(delay(0));
    }

    createOrUpdateMedicalFieldDirector(input: CreateOrUpdateAlaMedicalFieldDirector): Observable<void> {
        this.setStepToReview(AlaStatus.MedicalFieldDirector);
        return this.alaMedicalFieldDirectorServiceProxy.createOrUpdate(input).pipe(tap((status: AlaStatus) => {
            this.ala.medicalFieldDirector = input.medicalFieldDirector;
            this.ala.currentStep = status;
        }))
        .pipe(map((status: AlaStatus) => null));
    }

    getContact(): Observable<AlaContactDto> {
        return of(this.ala.contact).pipe(delay(0));
    }

    createOrUpdateContact(input: CreateOrUpdateAlaContact): Observable<void> {
        this.setStepToReview(AlaStatus.Contact);
        return this.alaContactServiceProxy.createOrUpdate(input).pipe(tap((status: AlaStatus) => {
            this.ala.contact = input.contact;
            this.ala.currentStep = status;
        }))
        .pipe(map((status: AlaStatus) => null));
    }

    getAcademicTitles(): Observable<AlaAcademicTitleDto[]> {
        return of(this.ala.academicTitles.titles);
    }

    createBlankAlaAcademicTitle(): Observable<number> {
        return this.alaAcademicTitleServiceProxy.createBlankAlaAcademicTitle(this.caseId);
    }

    deleteAcademicTitle(id: number): Observable<void> {
        return this.alaAcademicTitleServiceProxy.deleteAlaAcademicTitle(this.caseId, id);
    }

    createOrUpdateAcademicTitles(input: CreateOrUpdateAlaAcademicTitleInput): Observable<void> {
        this.setStepToReview(AlaStatus.AcademicTitle);
        return this.alaAcademicTitleServiceProxy.createOrUpdateAlaAcademicTitle(input).pipe(tap((output: CreateOrUpdateAlaAcademicTitleOutput) => {
            this.ala.academicTitles.titles = output.academicTitles;
            this.ala.currentStep = output.status;
        })).pipe(map(() => null));
    }

    getAcademicTitleFiles(forceRefresh?: boolean, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.alaAcademicTitleServiceProxy.getAlaAcademicTitleFiles(caseId, AlaStatus.AcademicTitle).pipe(tap((output: AlaDocumentsOutput) => {
                this.ala.academicTitles.documents = output.documents;
            })).pipe(map((output: AlaDocumentsOutput) => output.documents));
        }
        return of(this.ala.academicTitles.documents);
    }

    uploadAcademicTitleFiles(academicTitleId: number, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.alaAcademicTitleServiceProxy.uploadFiles(academicTitleId, input);
    }

    getAlaCurrentAssistants(): Observable<AlaCurrentAssistantDto[]> {
        return of(this.ala.currentAssistants).pipe(delay(0));
    }

    createBlankAlaCurrentAssistant(): Observable<number> {
        return this.alaCurrentAssistantServiceProxy.createBlankAlaCurrentAssistant(this.caseId);
    }

    createOrUpdateAlaCurrentAssistants(caseId: string, input: CreateOrUpdateAlaCurrentAssistantsInput): Observable<void> {
        this.setStepToReview(AlaStatus.CurrentAssistants);
        return this.alaCurrentAssistantServiceProxy.createOrUpdate(caseId, input).pipe(tap((status: AlaStatus) => {
            this.ala.currentAssistants = input.currentAssistants;
            this.ala.currentStep = status;
        }))
        .pipe(map((status: AlaStatus) => null));
    }

    deleteAlaCurrentAssistant(id: number): Observable<void> {
        return this.alaCurrentAssistantServiceProxy.deleteAlaCurrentAssistant(this.caseId, id);
    }

    getAlaPreviousEmployments(): Observable<AlaPreviousEmploymentDto[]> {
        return of(this.ala.previousEmployments).pipe(delay(0));
    }

    createBlankAlaPreviousEmployment(): Observable<number> {
        return this.alaPreviousEmploymentServiceProxy.createBlankAlaPreviousEmployment(this.caseId);
    }

    createOrUpdateAlaPreviousEmployments(caseId: string, input: CreateOrUpdateAlaPreviousEmploymentInput): Observable<void> {
        this.setStepToReview(AlaStatus.PreviousEmployment);
        return this.alaPreviousEmploymentServiceProxy.createOrUpdate(caseId, input).pipe(tap((status: AlaStatus) => {
            this.ala.previousEmployments = input.previousEmployments;
            this.ala.currentStep = status;
        }))
        .pipe(map((status: AlaStatus) => null));
    }

    deleteAlaPreviousEmployment(id: number): Observable<void> {
        return this.alaPreviousEmploymentServiceProxy.deleteAlaPreviousEmployment(this.caseId, id);
    }

    getLanguageSkillFiles(forceRefresh?: boolean, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.documentService.getDocuments(new GetAlaDocumentsInput({ caseId: caseId, step: AlaStatus.LanguageSkill })).pipe(tap((output: AlaDocumentsOutput) => {
                this.ala.documents = output.documents;
            })).pipe(map((output: AlaDocumentsOutput) => output.documents));
        }
        return of(this.ala.person.documents);
    }

    getLanguageSkill(): Observable<AlaLanguageSkillDto> {
        return of(this.ala.languageSkill).pipe(delay(0));
    }

    createOrUpdateLanguageSkill(input: CreateOrUpdateAlaLanguageSkillInput): Observable<void> {
        this.setStepToReview(AlaStatus.LanguageSkill);
        return this.alaLanguageSkillServiceProxy.createOrUpdate(input).pipe(tap((status: AlaStatus) => {
            this.ala.languageSkill = input.languageSkill;
            this.ala.currentStep = status;
        }))
        .pipe(map((status: AlaStatus) => null));
    }

    getPostgraduateTitles(): Observable<AlaPostgraduateTitleDto[]> {
        return of(this.ala.postgraduateTitles).pipe(delay(0));
    }

    createOrUpdatePostgraduateTitles(input: CreateOrUpdateAlaPostgraduateTitleInput): Observable<void> {
        this.setStepToReview(AlaStatus.PostgraduateTitle);
        return this.alaPostgraduateTitleServiceProxy.createOrUpdateLiaPostgraduateTitles(input).pipe(tap((output: CreateOrUpdateAlaPostgraduateTitleOutput) => {
            this.ala.postgraduateTitles = output.postgraduateTitles;
            this.ala.currentStep = output.status;
        }))
        .pipe(map((output: CreateOrUpdateAlaPostgraduateTitleOutput) => null));
    }

    getTrustworthinessFiles(forceRefresh?: boolean, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.documentService.getDocuments(new GetAlaDocumentsInput({ caseId: caseId, step: AlaStatus.Trustworthiness })).pipe(tap((output: AlaDocumentsOutput) => {
                this.ala.person.documents = output.documents;
            })).pipe(map((output: AlaDocumentsOutput) => output.documents));
        }
        return of(this.ala.person.documents);
    }

    trustworthinessProceed(): Observable<void> {
        this.setStepToReview(AlaStatus.Trustworthiness);
        return this.alaTrustworthinessServiceProxy.proceed(this.caseId).pipe(tap((status: AlaStatus) => {
            this.ala.currentStep = status;
        })).pipe(map(() => null));
    }


    getDiploma(): Observable<AlaDiplomaDto> {
        return of(this.ala.diploma).pipe(delay(0));
    }

    createOrUpdateDiploma(input: CreateOrUpdateAlaDiploma): Observable<void> {
        this.setStepToReview(AlaStatus.Diploma);
        return this.alaDiplomaServiceProxy.createOrUpdate(input).pipe(tap((status: AlaStatus) => {
            this.ala.diploma = input.diploma;
            this.ala.currentStep = status;
        }))
        .pipe(map((status: AlaStatus) => null));
    }

    getBusiness(): Observable<AlaBusinessDto> {
        return of(this.ala.business).pipe(delay(0));
    }

    createOrUpdateBusiness(caseId: string, input: AlaBusinessDto): Observable<void> {
        this.setStepToReview(AlaStatus.Business);
        return this.alaBusinessServiceProxy.createOrUpdate(caseId, input).pipe(tap((status: AlaStatus) => {
            this.ala.business = input;
            this.ala.currentStep = status;
        }))
        .pipe(map((status: AlaStatus) => null));
    }

    getEmployment(): Observable<AlaEmploymentDto> {
        return of(this.ala.employment).pipe(delay(0));
    }

    createOrUpdateEmployment(caseId: string, input: AlaEmploymentDto): Observable<void> {
        this.setStepToReview(AlaStatus.Employment);
        return this.alaEmploymentServiceProxy.createOrUpdate(caseId, input).pipe(tap((status: AlaStatus) => {
            this.ala.employment = input;
            this.ala.currentStep = status;
        }))
        .pipe(map((status: AlaStatus) => null));
    }

    getEmploymentFiles(forceRefresh?: boolean, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        if (forceRefresh) {
            return this.documentService.getDocuments(new GetAlaDocumentsInput({ caseId: caseId, step: AlaStatus.Employment })).pipe(tap((output: AlaDocumentsOutput) => {
                this.ala.documents = output.documents;
            })).pipe(map((output: AlaDocumentsOutput) => output.documents));
        }
        return of(this.ala.employment.documents);
    }

    getInvoiceAddress(): Observable<AlaInvoiceAddressDto> {
        return this.invoiceAddressService.get(this.caseId);
    }

    createOrUpdateInvoiceAddress(invoiceAddress: AlaInvoiceAddressDto): Observable<any> {
        this.setStepToReview(AlaStatus.InvoiceAddress);
        return this.invoiceAddressService.createOrUpdate(this._caseId, invoiceAddress).pipe(tap((newStatusId: AlaStatus) => {
            this.ala.statusId = newStatusId;
        }));
    }

    checkDocuments(): Observable<AlaStatus[]> {
        switch (this.application.type) {
            case ApplicationType.Alap:
                return this.alapService.validateThatAllMandatoryDocumentsHaveBeenUploaded(this.caseId);
            case ApplicationType.Alas:
                return this.alasService.validateThatAllMandatoryDocumentsHaveBeenUploaded(this.caseId);
        }
    }

    getUrlForStep(step: number): string {
        switch (step) {
            case AlaStatus.ApplicantPerson:
                return 'applicant-person';
            case AlaStatus.ApplicantSponsorship:
                return 'applicant-sponsorship';
            case AlaStatus.MedicalFieldDirector:
                return 'medical-field-director';
            case AlaStatus.Person:
                return 'person';
            case AlaStatus.Contact:
                return 'contact';
            case AlaStatus.Diploma:
                return 'diploma';
            case AlaStatus.AcademicTitle:
                return 'academic-title';
            case AlaStatus.LanguageSkill:
                return 'language-skill';
            case AlaStatus.PostgraduateTitle:
                return 'postgraduate-title';
            case AlaStatus.Business:
                return 'business';
            case AlaStatus.Employment:
                return 'employment';
            case AlaStatus.PreviousEmployment:
                return 'previous-employment';
            case AlaStatus.Trustworthiness:
                return 'trustworthiness';
            case AlaStatus.CurrentAssistants:
                return 'current-assistant';
            case AlaStatus.InvoiceAddress:
                return 'invoice-address';
            case AlaStatus.Release:
                return 'release';
            case AlaStatus.FinishReview:
                return 'finish-review';
            default:
                console.error('Invalid status for getUrlForStep');
                return null;
        }
    }
}
